// REELDECK
(() => {
  const reelDeck = document.querySelector('.tc_deck__reel');

  if (reelDeck && reelDeck.dataset.processed !== 'js') {

    // try runnning videoloop function from VideoLoop.vanilla.js
    // in case reel deck was loaded asynchronously and
    // the videoloop script already has run.
    typeof videoloop === 'function' && videoloop();
    typeof slider === 'function' && slider();

    reelDeck.setAttribute('data-processed', 'js');
  }
})();
